









import Vue from 'vue';

export default Vue.extend({
  props: {
    changeType: {
      type: String,
      required: true,
      validator(type: string): boolean {
        return ['+', '*', '-'].includes(type);
      },
    },
    changeText: {
      type: String,
      required: false,
      default: '',
    },
  },
});
