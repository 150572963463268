
































































import Vue from 'vue';
import '@/components/changelog/Change.vue'; // Chunk

export default Vue.extend({
  components: {
    Changelog202010: () => import(/* webpackChunkName: "cl-2020-10" */ '@/components/changelog/2020/10/TheChangelog.vue'),
    Changelog202011: () => import(/* webpackChunkName: "cl-2020-11" */ '@/components/changelog/2020/11/TheChangelog.vue'),
    Changelog202012: () => import(/* webpackChunkName: "cl-2020-12" */ '@/components/changelog/2020/12/TheChangelog.vue'),
    Changelog202101: () => import(/* webpackChunkName: "cl-2021-01" */ '@/components/changelog/2021/01/TheChangelog.vue'),
    Changelog202102: () => import(/* webpackChunkName: "cl-2021-02" */ '@/components/changelog/2021/02/TheChangelog.vue'),
    Changelog202103: () => import(/* webpackChunkName: "cl-2021-03" */ '@/components/changelog/2021/03/TheChangelog.vue'),
    Changelog202104: () => import(/* webpackChunkName: "cl-2021-04" */ '@/components/changelog/2021/04/TheChangelog.vue'),
  },
  meta() {
    return {
      title: this.$t('changelog').toString(),
    };
  },
});
